import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import NumberFormat from "react-number-format";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `excavation`,
          `tiling`,
          `drainage tiling`,
          `agricultural drainage tile`,
          `agricultural tiling`,
          `excavation contractor`,
          `residential excavation`,
          `commercial excavation`,
          `Ostrom Contracting`,
          `Mac Ostrom`,
        ]}
        title="Contact"
      />
      <section className="mb-12 overflow-x-hidden mx-5">
        <div className="container px-4 pt-12 pb-6 mx-auto">
          <h2 className="text-4xl uppercase font-bold mb-4 text-center">
            Contact Us
          </h2>
          <p className="text-base mb-8 text-center mx-auto">
            Looking for an estimate on a project or have a general question? Get
            in contact with us today to learn more about how we can help. Every
            project is different, and it is important to understand your
            specific requirements, budget, and timeline to come up with an
            estimate for you. Please contact us via phone by calling Mac Ostrom
            at (920) 362-0108, email at ostrom.mac@gmail.com, or by using the
            contact form below.{" "}
          </p>
        </div>
        <div className="max-w-9xl mx-auto flex flex-wrap">
          <div className="w-4/5 lg:w-1/3 mx-auto px-1 mb-12 flex flex-col justify-start md:px-4">
            <h3 className="text-center text-2xl mb-4 font-bold text-gray-900 lg:mb-16 lg:text-2xl xl:text-3xl">
              Contact Information
            </h3>
            <h4 className="block my-2 text-center mb-4 lg:mb-16">
              <FaPhoneAlt className="inline-block text-xl mr-3 align-middle lg:text-xl xl:text-3xl" />
              <a
                href="tel:+19203620108"
                className="inline-block align-middle text-gray-700 text-xl transition duration-500 ease-in-out hover:text-gray-900 lg:text-xl xl:text-3xl"
              >
                (920) 362-0108
              </a>
            </h4>
            <h4 className="block my-2 text-center mb-4 lg:mb-16">
              <FaEnvelope className="inline-block text-xl mr-3 align-middle lg:text-xl xl:text-3xl" />
              <a
                href="mailto:ostrom.mac@gmail.com"
                className="inline-block align-middle text-gray-700 text-xl transition duration-500 ease-in-out hover:text-gray-900 lg:text-xl xl:text-3xl"
              >
                ostrom.mac@gmail.com
              </a>
            </h4>
            <h4 className="block my-2 text-center">
              <FaMapMarkerAlt className="inline-block text-xl mr-3 align-middle lg:text-xl xl:text-3xl" />
              <a
                href="https://www.google.com/maps/place/Freedom,+WI+54130/@44.4277676,-88.3481182,11z/data=!4m5!3m4!1s0x8803ad2b34e38c3b:0x1ddfce742b428a3a!8m2!3d44.3865588!4d-88.2884368"
                rel="noreferrer"
                target="_blank"
                className="inline-block align-middle text-gray-700 text-xl transition duration-500 ease-in-out hover:text-gray-900 lg:text-xl xl:text-3xl"
              >
                Freedom Wisconsin
              </a>
            </h4>
          </div>

          <form
            name="Contact Form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            className="mx-auto mb-12 md:w-2/3 xl:w-1/2"
          >
            <input
              type="hidden"
              name="form-name"
              value="Contact Form"
              className="hidden"
            />

            <p className="hidden">
              <label>
                Don’t fill this out if you are a real person:{" "}
                <input name="bot-field" />
              </label>
            </p>

            <label
              className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="w-full mb-6 form-input border focus:border-gray-900 focus:shadow-none"
              name="name"
              id="name"
              placeholder="John Doe"
              type="text"
              required
            />

            <label
              className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md"
              htmlFor="phone"
            >
              Phone Number
            </label>

            <NumberFormat
              name="phone"
              id="phone"
              className="w-full mb-6 form-input border focus:border-gray-900 focus:shadow-none"
              format="+1 (###) ###-####"
              allowEmptyFormatting
              mask="_"
            />

            {/* <input
                className="w-full mb-6 form-input border focus:border-gray-900 focus:shadow-none"
                id="phone"
                placeholder="123-456-7890"
                type="text"
                required
              /> */}

            <label
              className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md"
              htmlFor="email"
            >
              Email
            </label>

            <input
              className="w-full mb-6 form-input border focus:border-gray-900 focus:shadow-none"
              name="email"
              id="email"
              placeholder="johndoe@gmail.com"
              type="email"
            />

            <label
              className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md"
              htmlFor="message"
              required
            >
              Message
            </label>

            <textarea
              className="w-full mb-6 form-textarea border focus:border-gray-900 focus:shadow-none"
              name="message"
              id="message"
              placeholder="Please provide any details about the services you are inquiring about or any other questions you may have"
              rows="8"
              required
            />

            <button
              className="px-4 py-2 text-lg font-bold text-white bg-gray-900 rounded hover:text-ocyellow"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
